<template src="./index.html"></template>
<style src="./index.less" lang="less" scoped></style>
<script>
import self_mask_popup from "@/components/self_mask_popup";
import { mapState } from "vuex";
export default {
  name: "ApplicationNoPopup",
  components:{self_mask_popup},
  computed: { 
    ...mapState({
      getPrintProgress: (state) => state.print.getPrintProgress,
    }),
  },
  methods: {
    close(){
      this.$emit("close")
    }
  },
}
</script>