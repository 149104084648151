<template>
  <div>
    <!-- 快递单设置发件人弹窗 -->
    <my-dialog-3 :centerDialogVisible="dialogExpressConsignerVisible" :popupWidth="'500px'"
      @updateVisible="updateExpressConsignerVisible" @openDialog="openExpressConsignerDialog">
      <template slot="content">
        <div class="consigner-dialog">
          <span style="margin-bottom: 30px">请选择快递单发件人</span>
          <el-radio-group v-model="activeExpressConsignerIndex">
            <el-radio v-for="(item, index) in expressConsignerList" :key="index" :label="index + ''">{{ item.name }}
            </el-radio>
          </el-radio-group>
          <span v-show="expressConsignerList.length == 0">暂无发件人信息，点击<el-button type="text" @click="jumptoSetting()">这里
            </el-button>进行设置</span>
        </div>
      </template>
      <template slot="footer">
        <el-button class="footer-btn btn1" type="primary" @click="handleChooseExpressConsigner" size="mini">确定
        </el-button>
        <el-button class="footer-btn btn2" size="mini" @click="handleCancel">取消</el-button>
      </template>
    </my-dialog-3>
    <!-- 打印快递单弹窗 -->
    <my-dialog-3 :centerDialogVisible="dialogExpressVisible" :popupWidth="'500px'"
      @updateVisible="updateExpressVisible">
      <template slot="content">
        <div class="express-print-dialog">
          <el-form label-position="right" label-width="120px" :model="expressPrintOption">
            <el-form-item v-if="type == 1" label="打印行数：">
              <span>共{{ selectRowLength }}行</span>
            </el-form-item>
            <el-form-item v-if="type == 1" label="快递模板：">
              <el-button type="text" style="margin-right: 20px">{{
                  expressTemplateList[expressTemplate].express_name
              }}</el-button>
            </el-form-item>
            <el-form-item label="选择打印机：">
              <el-select v-model="expressPrintOption.printer" placeholder="选择打印机" size="mini">
                <el-option v-for="(item, index) in printerList" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设置打印份数：">
              <el-select v-model="expressPrintOption.num" placeholder="选择份数" size="mini">
                <el-option label="1" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template slot="footer">
        <el-button class="footer-btn btn1" type="primary" size="mini" @click="DetectPrintedExpress">打印快递单</el-button>
        <el-button class="footer-btn btn2" size="mini" @click="dialogExpressVisible = false">取消</el-button>
      </template>
    </my-dialog-3>

    <el-dialog width="300px" :visible="SelectCustomTemplateList.length!=0" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
      <div style="padding: 50px 20px 10px;display:flex;justify-content: center;">
        <el-select v-model="SelectCustomTemplateKey" placeholder="请选择自定义区模板">
          <el-option v-for="(item, index) in SelectCustomTemplateList" :label="item" :value="item"></el-option>
        </el-select>
      </div>
      <div slot="footer">
        <el-button style="color:#333;background:#fff;border:1px solid #333;padding: 6px 20px;" @click="SelectCustomTemplateList = []">取 消</el-button>
        <el-button style="padding: 6px 20px;" type="primary" @click="() => {
          this.SelectCustomTemplateList = [];
          this.DetectPrintedExpress();
        }">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 已打印过的快递单提示框 -->
    <reprintDialog2 v-if="dialogShow5" @use_new="doPrintExpress(0)" @use_old="doPrintExpress(1)"
      @cancel="() => { this.dialogShow5 = false }" />
    <!-- 申请电子面单进度条弹窗 -->
    <ApplicationNoPopup @close="show_ApplicationNoPopup=false" v-if="show_ApplicationNoPopup"></ApplicationNoPopup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import myDialog3 from "@/UI/myDialog3";
import reprintDialog2 from "@/components/dialog/reprintDialog2";
import ApplicationNoPopup from "@/components/ApplicationNoPopup";
export default {
  // type:1批量 type:2单个
  props: ["selectRowLength", 'orderInfoList', 'has_express_print_state', 'type'],
  components: {
    myDialog3,
    reprintDialog2,
    ApplicationNoPopup,
  },
  data() {
    let printerList = new Array();
    printerList = printerList.concat(
      this.PRINTERLIST || [], 
      window.cainiao_print_list || [], 
      window.douyin_print_list || [], 
      window.kuaishou_print_list || []
    )
    printerList = printerList.filter((item, index) => printerList.indexOf(item) === index)
    console.log(printerList, '---printerList')
    return {
      // 打印机列表
      printerList,
      // 快递单发货人设置弹窗变量
      activeExpressConsignerIndex: "0",
      // 快递打印确认弹窗变量
      dialogExpressVisible: false,
      // 快递打印确认弹窗绑定数据
      expressPrintOption: {
        printer: "",
        num: "1",
      },
      dialogShow5: false, // 判断是否有打印过的快递单
      SelectCustomTemplateKey: "",
      SelectCustomTemplateList: [],
      show_ApplicationNoPopup:false, // 显示获取单号进度弹窗
    }
  },
  computed: {
    ...mapState({
      expressConsignerList: (state) => state.print.expressConsignerList,
      expressTemplateList: (state) => state.print.expressTemplateList,
      // 打印参数
      printOrderInfo: (state) => state.batchprint.printOrderInfo,
    }),
    expressTemplate: {
      get() {
        return this.$store.state.batchprint.expressTemplate;
      },
      set(val) {
        this.$store.commit("batchprint/SET_EXPRESS_TEMPLATE", val);
      },
    },
    dialogExpressConsignerVisible: {
      get() {
        return this.$store.state.batchprint.dialogExpressConsignerVisible
      }
    }
  },
  methods: {
    handleCancel() {
      this.$store.commit('batchprint/SAVE', { dialogExpressConsignerVisible: false })
    },
    // 跳转设置
    jumptoSetting() {
      this.$router.push("/setting");
    },
    // 快递单发件人设置弹窗函数
    updateExpressConsignerVisible(val) {
      this.$store.commit('batchprint/SAVE', { dialogExpressConsignerVisible: val })
    },
    openExpressConsignerDialog() {
      this.$store.dispatch("print/get_express_consigner_list");
    },
    handleChooseExpressConsigner() {
      let expressConsigner = this.expressConsignerList[
        this.activeExpressConsignerIndex
      ];
      this.expressConsigner = expressConsigner;
      this.$store.commit('batchprint/SAVE', { dialogExpressConsignerVisible: false })
      this.dialogExpressVisible = true;
    },
    // 快递弹窗函数
    updateExpressVisible(val) {
      this.dialogExpressVisible = val;
    },
    // 判断是否存在打印过订单
    async DetectPrintedExpress() {
      this.dialogExpressVisible = false

      let template_id = this.expressTemplateList[this.expressTemplate]["id"]
      // 获取自定义区模板
      if (!this.SelectCustomTemplateKey) {
        // this.orderInfoList[0].outcode == "douyin"
        let url = ""
        switch (this.orderInfoList[0].outcode) {
          case "douyin":
            url = "print/get_custom_template_name"
            break;
          case "kuaishou":
            url = "print/get_ks_custom_template_name"
            break;
        }
        if(url){
          let res = await this.$store.dispatch(url, { template_id })
          if (res.data && res.data.length) {
            this.SelectCustomTemplateList = res.data
            return false
          }
        }
      }
      if (!this.expressPrintOption.num || !this.expressPrintOption.printer) {
        this.$message.error("请选择打印机和打印份数");
        return false;
      } else if (this.has_express_print_state || this.orderInfoList.express_print_state == 1) {
        this.dialogShow5 = true
      } else {
        this.doPrintExpress(0)
      }
    },
    // 打印快递单参数
    doPrintExpressParams(params, paramsValue) {
      let tid = []
      let time = []
      let tParamId = []
      let createTime = []
      if (paramsValue.merge_order) {
        for (let k in paramsValue.merge_order) {
          tid = paramsValue.merge_order[k].transaction_id
          time = paramsValue.merge_order[k].create_time
          tParamId.push(tid)
          createTime.push(time)
        }
        params.transaction_id.push(tParamId)
        params.create_time.push(createTime)
      } else {
        params.create_time.push(paramsValue.create_time)
        params.transaction_id.push(paramsValue.transaction_id)
      }
      return params
    },
    // 打印函数
    async doPrintExpress(is_old) {
      let template_id = this.expressTemplateList[this.expressTemplate]["id"]

      let params = {
        template_id,
        company_template: this.SelectCustomTemplateKey,
        is_old: is_old == 1 ? is_old : 0, // 0为新单号打印 1为原单号
        create_time: [],
        transaction_id: [],
        address_express_id: this.expressConsignerList[this.activeExpressConsignerIndex].id,
      }
      // 批量打印
      if (this.type == 1) {
        if (this.printOrderInfo.length == 0) {
          this.$message.error("请选择要打印的订单");
        } else {
          this.orderInfoList.map((item, index) => {
            if (item.is_hang != 1) {
              params = this.doPrintExpressParams(params, item)
            }
          })
        }
      }
      // 单个打印
      if (this.type == 2) {
        params = this.doPrintExpressParams(params, this.orderInfoList)
      }
      // 获取打印数据
      let GetExpressOrderInfoRes = await this.$store.dispatch("print/GetExpressOrderInfo", params)
      
      this.SelectCustomTemplateKey = ""
      this.dialogShow5 = false
      // 创建获取快递单后台任务失败
      if (GetExpressOrderInfoRes.code != 0) {
        this.$message.error(GetExpressOrderInfoRes.message);
        return false
      }

      // 创建任务成功
      this.show_ApplicationNoPopup = true
      this.$store.dispatch("print/getPrintProgress",(res)=>{
        console.log('轮询打印回调：',res);
        let data = res.data.info.data
        if (data.code == 0 && data.message != "成功") {
          this.$store.commit("print/SAVE",{GetExpressError:data.message})
        }else if(data.code != 0){
          this.$message({
            type: 'error',
            message: data.message
          });
        }
        if (data.data.length > 0) {
          // params 参数： printer 打印机, waybillArray 订单数组 , preview = false //true预览 false打印
          let print_params = {
            printer: this.expressPrintOption.printer,
            preview: false,
            data: data.data
          }
          if (this.orderInfoList[0].outcode == "douyin") {
            let TaskID = window.douyin_print.doPrint(print_params)
          } else if (this.orderInfoList[0].outcode == "kuaishou") {
            let TaskID = window.kuaishou_print.doPrint(print_params)
          } else {
            let TaskID = window.cainiao_print.doPrint(print_params)
          }
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>

</style>