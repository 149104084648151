<template src="./index.html"></template>
<style src="./index.less" lang="less" scoped></style>
<script>
import self_mask_popup from "@/components/self_mask_popup";
export default {
  name: "express_notes",
  components:{self_mask_popup},
  methods: {
    close(){
      this.$emit("close",{})
    }
  },
}
</script>