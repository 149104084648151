<template>
  <div class="box">
    <div class="search-form-container">
      <div class="search-form" ref="searchForm">
        <slot name="form">
          <el-form
            :inline="true"
            :rules="formRules"
            ref="ruleSearchForm"
            :model="searchInfo"
            :style="{ width: formWidth }"
          >
            <el-form-item prop="Time">
              <el-select
                v-model="searchInfo.Time"
                placeholder="排序方式"
                size="mini"
                style="width: 110px"
              >
                <el-option label="按下单时间" value="1"></el-option>
                <el-option label="按修改时间" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="startTime">
              <el-date-picker
                v-model="searchInfo.startTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetimerange"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                size="mini"
                style="width: 310px"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="searchInfo.status"
                placeholder="付款状态"
                size="mini"
                style="width: 110px"
              >
                <el-option label="全部订单" value="0"></el-option>
                <el-option label="等待买家付款" value="1"></el-option>
                <el-option label="买家已付款" value="2"></el-option>
                <el-option label="卖家已发货" value="3"></el-option>
                <el-option label="交易成功" value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="searchInfo.print_state"
                placeholder="打印状态"
                size="mini"
                style="width: 110px"
              >
                <el-option label="全部" value="0"></el-option>
                <!-- <el-option label="打印状态" value="0"></el-option> -->
                <el-option label="仅快递单已打印" value="1"></el-option>
                <el-option label="仅发货单已打印" value="2"></el-option>
                <el-option label="快递单发货单已打印" value="3"></el-option>
                <el-option label="快递单发货单未打印" value="4"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item>
                <el-select v-model="searchInfo.quantity_type" placeholder="选择数量查询" size="mini" style="width: 110px;">
                    <el-option label="选择数量查询" value="0"></el-option>
                    <el-option label="宝贝总数量" value="1"></el-option>
                    <el-option label="单款单件" value="2"></el-option>
                    <el-option label="单款多件" value="3"></el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item v-show="searchInfo.quantity_type=='1'">
                <el-input  v-model="searchInfo.quantity_value" placeholder="请输入查询数量" size="mini" style="width: 150px;" >
                    <!-- <i @click='buyWangShow(9)' slot="suffix" style="font-size:16px;line-height:43px;cursor: pointer;" class="el-icon-edit-outline"></i> -->
                </el-input>
            </el-form-item>
            <el-form-item v-if="showAll">
              <el-select v-model="searchInfo.remark_type" placeholder="留言备注" size="mini" :style="{
                      width: searchInfo.remark_type == '4' || searchInfo.remark_type == '9' ? '180px' : '110px',
                    }">
                <el-option label="留言备注" value="1"></el-option>
                <el-option label="有买家留言" value="2"></el-option>
                <el-option label="有卖家备注" value="3"></el-option>
                <el-option label="有买家留言+有卖家备注" value="4"></el-option>
                <el-option label="无买家留言+无卖家备注" value="9"></el-option>
                <el-option label="蓝旗" value="5"></el-option>
                <el-option label="绿旗" value="6"></el-option>
                <el-option label="黄旗" value="7"></el-option>
                <el-option label="红旗" value="8"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="searchClass" v-if="showAll">
              <el-select
                v-model="searchInfo.ww_baby_count_money"
                placeholder="选择查询条件：旺旺/宝贝/数量/金额"
                id="searchId"
                size="mini"
                :style="{
                  width: searchInfo.ww_baby_count_money == '' ? '230px' : '125px',
                }"
              >
                <el-option label="选择查询条件：旺旺/宝贝/数量/金额" value=""></el-option>
                <el-option label="买家旺旺" value="1"></el-option>
                <el-option label="宝贝总数量" value="3"></el-option>
                <el-option label="订单实付金额" value="4"></el-option>
                <!-- <el-option label="订单数量" value="5"></el-option> -->
                <el-option label="订单编号" value="6"></el-option>
                <el-option label="手机号" value="2"></el-option>
                <el-option label="卖家备注" value="7"></el-option>
                <el-option label="快递单号" value="8"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="searchInfo.ww_baby_count_money == '1'">
              <el-input
                v-model="queryData.wangwang"
                placeholder="请输入买家旺旺"
                size="mini"
                style="width: 150px"
                @keyup.native="handleKeyNumber(queryData.wangwang)"
              >
                <i
                  @click="buyWangShow(1)"
                  slot="suffix"
                  style="font-size: 16px; line-height: 43px; cursor: pointer"
                  class="el-icon-edit-outline"
                ></i>
              </el-input>
            </el-form-item>
            <el-form-item v-if="searchInfo.ww_baby_count_money == '2'" >
              <el-input
                v-model="queryData.to_mobile"
                placeholder="请输入完整手机号"
                size="mini"
                style="width: 150px"
                @keyup.native="handleKeyNumber(queryData.to_mobile)"
              >
                <i
                  @click="buyWangShow(2)"
                  slot="suffix"
                  style="font-size: 16px; line-height: 43px; cursor: pointer"
                  class="el-icon-edit-outline"
                ></i>
              </el-input>
            </el-form-item>
            <el-form-item v-if="searchInfo.ww_baby_count_money=='3'">
                  <el-input  v-model="queryData.shopNumber" placeholder="请输入宝贝总数量" size="mini" style="width: 150px;"
                  @keyup.native="handleKeyNumber(queryData.shopNumber)" >
                      <!-- <i @click='buyWangShow(3)' slot="suffix" style="font-size:16px;line-height:43px;cursor: pointer;" class="el-icon-edit-outline"></i> -->
                  </el-input>
              </el-form-item>
                <el-form-item v-if="searchInfo.ww_baby_count_money=='4'">
                  <el-input  v-model="queryData.orderMoney" placeholder="请输入订单实付金额" size="mini" style="width: 150px;"
                  @keyup.native="handleKeyNumber(queryData.orderMoney)" >
                      <!-- <i @click='buyWangShow(4)' slot="suffix" style="font-size:16px;line-height:43px;cursor: pointer;" class="el-icon-edit-outline"></i> -->
                  </el-input>
              </el-form-item>
              <!-- <el-form-item v-if="searchInfo.ww_baby_count_money=='5'">
                  <el-input  v-model="queryData.orderNumber" placeholder="请输入订单数量" size="mini" style="width: 150px;" 
                  @keyup.native="handleKeyNumber(queryData.orderNumber)">
                      <i @click='buyWangShow(5)' slot="suffix" style="font-size:16px;line-height:43px;cursor: pointer;" class="el-icon-edit-outline"></i>
                  </el-input>
              </el-form-item> -->
              <el-form-item v-if="searchInfo.ww_baby_count_money=='6'">
                  <el-input  v-model="queryData.orderCode" placeholder="请输入订单编号" size="mini" style="width: 150px;" @keyup.native="handleKeyNumber(queryData.orderCode)">
                      <i @click='buyWangShow(6)' slot="suffix" style="font-size:16px;line-height:43px;cursor: pointer;" class="el-icon-edit-outline"
                      ></i>
                  </el-input>
              </el-form-item>
              <el-form-item v-if="searchInfo.ww_baby_count_money=='7'">
                  <el-input  v-model="queryData.remark" placeholder="请输入卖家备注" size="mini" style="width: 150px;" 
                  @keyup.native="handleKeyNumber(queryData.remark)">
                      <!-- <i @click='buyWangShow(7)' slot="suffix" style="font-size:16px;line-height:43px;cursor: pointer;" class="el-icon-edit-outline"></i> -->
                  </el-input>
              </el-form-item>
              <el-form-item v-if="searchInfo.ww_baby_count_money=='8'">
                  <el-input  v-model="queryData.expressNumber" placeholder="请输入快递编号" size="mini" style="width: 150px;" @keyup.native="handleKeyNumber(queryData.expressNumber)">
                      <i @click='buyWangShow(8)' slot="suffix" style="font-size:16px;line-height:43px;cursor: pointer;" class="el-icon-edit-outline"></i>
                  </el-input>
              </el-form-item>
              
            <el-form-item v-if="showAll">
                  <el-select v-model="searchInfo.delivery_limit_type" placeholder="剩余发货时间" size="mini" style="width: 110px;">
                      <el-option label="所有剩余时间" value="0"></el-option>
                      <el-option label="已超时" value="1"></el-option>
                      <el-option label="剩余时间小于24小时" value="2"></el-option>
                  </el-select>
              </el-form-item>

            <el-form-item v-if="showAll">
              <el-select
                v-model="searchInfo.refund_state"
                placeholder="退款状态"
                size="mini"
                style="width: 110px"
              >
                <el-option label="全部" value="0"></el-option>
                <el-option label="等待卖家同意" value="1"></el-option>
                <el-option label="等待买家退货" value="2"></el-option>
                <el-option label="等待卖家确认收货" value="3"></el-option>
                <el-option label="退款成功" value="4"></el-option>
                <el-option label="待买家修改" value="5"></el-option>
                <el-option label="退款失败" value="6"></el-option>
              </el-select>
            </el-form-item>
            
            <el-form-item v-if="showAll && isShowCanal=='1'">
              <el-select
                v-model="searchInfo.outcode"
                placeholder="渠道订单"
                size="mini"
                style="width: 110px"
              >
                <el-option label="全部" value="0"></el-option>
                <!-- <el-option label="1688" value="1"></el-option> -->
                <el-option label="淘宝" value="2"></el-option>
                <el-option label="抖音" value="3"></el-option>
                <el-option label="快手" value="4"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </slot>
      </div>
      <div class="search-menu">
        <!-- 表单折叠展开按钮 -->
        <el-button
          type="text"
          v-show="foldButton"
          @click="foldSearchForm"
          style="margin-right: 10px; color: #a1a1a1; text-decoration: none"
        >
          {{ word }}
          <i
            :class="showAll ? 'el-icon-arrow-up' : 'el-icon-d-arrow-right'"
            style="color: #409eff"
          ></i>
          <!-- <i :class="showAll ? 'iconfont icon-up': 'iconfont icon-down'"></i> -->
        </el-button>
        <!-- menu插槽 -->
        <slot name="menu">
          <el-button type="primary" size="mini" @click="Search">查询</el-button>
        </slot>
      </div>
      <el-dialog
        :visible.sync="dialogShow"
        width="30%"
        background-color="#fff"
        center
      > 
        <div class="contentClass">
          <p class="topBg"></p>
          <div class="topTitle">
            <span>批量添加</span>
            <span>批量添加（逗号换行）或从Excel中复制粘贴</span>
          </div>
          <div class="contentDiv">
            <p>当前最大添加数量{{maxNumber}}条</p>
            <el-input
              type="textarea"
              :rows="8"
              v-model="addBuyValue"
              resize="none"
              @keyup.native="handleFontShow"
            >
            </el-input>
            <p style="color: red" v-if="keyNumberShow">输入的内容已超过{{maxNumber}}条</p>
          </div>
          <div class="contentFooter">
            <div @click="comfirBtn">确定</div>
            <div @click="noneBtn">取消</div>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        :visible.sync="dialogShow2"
        width="23%"
        background-color="#fff"
        center
      >
        <div class="contentClass contentClass2">
          <p class="topBg"></p>
          <div class="topTitle">
            <span>重新查询提醒</span>
          </div>
          <p class="contentText">
            页面停留时间过长，为避免订单信息变更同步不准确，请重新查询订单！
          </p>
          <div class="contentFooter">
            <div @click="dialogShow2=false">继续打印</div>
          </div>
        </div>
      </el-dialog>
      <div class="print-show">
        <el-dialog :visible.sync="printShow" width="350px" :modal='false'>
          <p class="content"><i class="el-icon-warning"></i>最大可添加数量{{maxNumber}}条</p>
        </el-dialog>
      </div>
    </div>
    <div style="margin-right: 70px;">
      <!-- 正在申请面单 -->
      <div v-if="getPrintProgress.status=='execute'" style="display: flex;align-items: center;">
        <span class="progress_title">申请面单进度：</span>
        <div class="progress_box">
          <el-progress :show-text="false" :stroke-width="10" color="#006CFA"
            :percentage="(getPrintProgress.info.nowtasknum/getPrintProgress.info.alltasknum)*100"
            v-if="!isNaN(parseInt(getPrintProgress.info.nowtasknum/getPrintProgress.info.alltasknum))"
          ></el-progress>
        </div>
        <span class="progress_number">{{getPrintProgress.info.nowtasknum}}/{{getPrintProgress.info.alltasknum}}单</span>
        <i @click="show_express_notes=true" class="iconfont icon-wenhaoxiao" style="cursor: pointer;"></i>
      </div>
      <!-- 面单申请结果 -->
      <div v-else-if="get_shop_info.last_print_time!='0000-00-00 00:00:00'" style="display: flex;align-items: center;font-size: 12px;color:#616266;">
        <span style="color: #4E7ECF;">上次申请面单结果：</span>
        <span style="margin-right: 8px;">上次打印时间/订单数</span>
        <span>{{ get_shop_info.last_print_time }} / {{ get_shop_info.last_print_num }}单</span>
      </div>
      <express_notes v-if="show_express_notes" @close="show_express_notes=false"></express_notes>
    </div>
  </div>
</template>

<script>
import express_notes from "@/components/express_notes";
import { mapState } from "vuex";
export default {
  name: "PrintSearchForm",
  components:{express_notes},
  props: {
    // 表单部分宽度，用来控制表单换行
    // 默认宽度为700px，如果需要更改换行则传递参数
    // 如果自己传入el-form，则不需要formWidth
    formWidth: {
      type: String,
      default() {
        return "730px";
      },
      required: false,
    },
    // 是否添加折叠按钮
    foldButton: {
      type: Boolean,
      default() {
        return true;
      },
    },
    // 如果使用封装在内部的表单，则需要绑定表单数据
    formData: {
      type: Object,
      default() {
        return {
          // 查询按什么时间
          Time: "1",
          // 开始时间
          startTime: "",
          // 结束时间
          endTime: "",
          // 订单状态
          status: "",
          // 按什么查询
          ww_baby_count_money: "",
          // 订单类型
          trade_type: "",
          // 打印状态
          print_state: "",
          // 退款状态
          refund_state: "",
          // 买家旺旺
          wangwang: "",
          // 商品名称
          productName: "",
          // 页码
          pageNum: "1",
          // 每页个数
          limit: "20",
        };
      },
      required: false,
    },
    // 是否显示渠道订单,1显示，0不显示
    isShowCanal:{
      default: "0"
    }
  },
  data() {
    return {
         queryData:{
            wangwang:'',//买家旺旺
            to_mobile:'',//手机号 
            shopNumber:'',//商品数量
            orderMoney:'',//订单总金额
            orderNumber:'',//订单数量
            orderCode:'',//订单编号
            remark:'',//买家备注
            expressNumber:''//快递单号
        },

      // 时间日期选择器选项
      pickerOptions: {
        shortcuts: [
          // {
          //     text: "记住天数",
          //         onClick(picker) {
          //     },
          // },
          {
            text: "近1周",
            onClick(picker) {
              const end = new Date();
              const start = new Date(end - 86400 * 1000 * 6);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近1个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date(end - 86400 * 1000 * 29);
            //   start.setMonth(start.getMonth() - 1);
            //   start.setDate(start.getDate() + 1);
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setHours(0);
              start.setMinutes(0);
              start.setSeconds(0);
              start.setMilliseconds(0);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        disabledDate: (date) => {
          // 限制查询3个月内数据
          let moment = this.moment;
          if (
            moment(date).isBefore(moment().subtract(2, "M").startOf("M")) ||
            moment(date).isAfter(moment().endOf("D"))
          ) {
            return true;
          }
        },
      },
      // 表单是否折叠控制参数
      showAll: true,
      // 表单验证规则
      formRules: {
        Time: [
          {
            required: true,
            message: "请选择查询条件",
            trigger: ["blur", "change"],
          },
        ],
        startTime: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
      },
      // 输入弹窗
      dialogShow: false,
      addBuyValue: "",
      inputNum: "",
      dialogShow2: false, // 页面停留时间过长
      checked: false,
      tagShow: false,
      keyNumber: 0,
      keyNumberShow: false,
      printShow: false,
      maxNumber: 50, //最大条数
      time_out_minutes: 0, // 分钟计时
      show_express_notes: false,
    };
  },
  computed: {
    ...mapState({
      getPrintProgress: (state) => state.print.getPrintProgress,
      get_shop_info: (state)=> state.setting.get_shop_info.data,
    }),
    // 折叠按钮显示的内容
    // 表单折叠时显示 "更多选项"
    word: function () {
      if (this.showAll == false) {
        return "更多选项 ";
      } else {
        return "收起 ";
      }
    },
    // search-form绑定的数据
    // 计算searchInfo变化
    searchInfo: {
      // 获取初始值printFormData
      get() {
        return this.formData;
      },
      // 当searchInfo值发生变化时，调用updateFormData传值
      set(val) {
        this.$emit("updateFormData", val);
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.time_out_timer);
  },
  mounted() {
    this.$store.dispatch("print/getPrintProgress")
    this.$nextTick(function () {
      this.foldSearchForm();
    });
  },
  methods: {
    // 表单折叠展开函数
    foldSearchForm() {
      this.showAll = !this.showAll;
      var searchForm = this.$refs.searchForm;
      if (this.showAll == false) {
        searchForm.style.height = 50 + "px";
      } else {
        searchForm.style.height = "auto";
      }
    },
    // 停留超时计时
    timeOut() {
      clearTimeout(this.time_out_timer);
      this.time_out_timer = setTimeout(() => {
        this.dialogShow2 = true;
      }, 1000 * 60 * 5);
    },
    // 查询按钮，调用submitSearch
    Search() {
      this.$store.commit("singleprint/save",{singleprint_nodata:false})
      if(this.searchInfo.ww_baby_count_money==1){
        if(!this.queryData.wangwang){
          this.$message.error("旺旺名不能为空");
          return false
        }else{
          this.searchInfo.wbcm_value=this.queryData.wangwang.replace(/，/g,',')
        }
      }
      if(this.searchInfo.ww_baby_count_money==2){
        if(this.queryData.to_mobile.length == 0){
          this.$message.error("请输入正确手机号");
          return false
        }
          let phone=this.queryData.to_mobile.replace(/，/g,',').split(",")
          var telNumber = phone.filter(function (s) {
          return s && s.trim(); 
        });
          for (let i = 0; i < telNumber.length; i++) {
            if(telNumber[i].length==11){
              this.searchInfo.wbcm_value=telNumber.join(",")
            }else{
              this.$message.error("请输入正确手机号");
              return false
            }
          }
      }
      if(this.searchInfo.ww_baby_count_money==3){
        if(!this.queryData.shopNumber){
          this.$message.error("宝贝总数量不能为空");
          return false
        }else{
          this.searchInfo.wbcm_value=this.queryData.shopNumber
          }
      }
      if(this.searchInfo.ww_baby_count_money==4){
        if(!this.queryData.orderMoney){
          this.$message.error("订单实付金额不能为空");
          return false
        }else{
          this.searchInfo.wbcm_value=this.queryData.orderMoney
          }
      }
      if(this.searchInfo.ww_baby_count_money==5){
        if(!this.queryData.orderNumber){
          this.$message.error("订单数量不能为空");
          return false
        }else{
          this.searchInfo.wbcm_value=this.queryData.orderNumber
          }
      }
      if(this.searchInfo.ww_baby_count_money==6){
        if(!this.queryData.orderCode){
          this.$message.error("订单编号不能为空");
          return false
        }else{
          this.searchInfo.wbcm_value=this.queryData.orderCode.replace(/，/g,',')
          }
      }
      if(this.searchInfo.ww_baby_count_money==7){
        if(!this.queryData.remark){
          this.$message.error("卖家备注不能为空");
          return false
        }else{
          this.searchInfo.wbcm_value=this.queryData.remark
          }
      }
      if(this.searchInfo.ww_baby_count_money==8){
        if(!this.queryData.expressNumber){
          this.$message.error("快递单号不能为空");
          return false
        }else{
          this.searchInfo.wbcm_value=this.queryData.expressNumber.replace(/，/g,',')
          }
      }
      this.searchInfo.pageNum=1
      this.$refs.ruleSearchForm.validate((valid) => {
        let moment = this.moment;
        if (
          !(
            moment(this.searchInfo.startTime[1]).diff(
              this.searchInfo.startTime[0],
              "day"
            ) < 35
          )
        ) {
          this.$message.error("一次搜索最多可选35天");
          return false;
        }
        if (valid) {
          // 停留超时计时
          this.time_out_minutes = 0;
          this.timeOut();
          this.$emit("submitSearch");
        } else {
          return false;
        }
      });
    },
    // dateTimePicker选择时间之后触发
    // timeChange (val) {
    //     // this.formData.endTime = this.dateTime[1];
    //     // this.formData.startTime = this.dateTime[0];
    // }
    buyWangShow(num) {
      this.inputNum = num;
        if (num == 1){
          this.maxNumber=50 
           if(this.queryData.wangwang){
            this.addBuyValue = this.queryData.wangwang
          }else{
             this.addBuyValue = ''
          }
        }//旺旺名
         
        if (num == 2){
          this.maxNumber=20
          if(this.queryData.to_mobile){
            this.addBuyValue = this.queryData.to_mobile
          }else{
             this.addBuyValue = ''
          }
        }//手机号
        // if (num == 3) this.addBuyValue = this.queryData.shopNumber ?this.queryData.shopNumber :'' //宝贝总数量
        // if (num == 4) this.addBuyValue = this.queryData.orderMoney ?this.queryData.orderMoney :'' //订单总金额
        // if (num == 5) this.addBuyValue = this.queryData.orderNumber ?this.queryData.orderNumber :'' //订单数量
        if (num == 6){
          this.maxNumber=200
          if(this.queryData.orderCode){
            this.addBuyValue = this.queryData.orderCode
          }else{
             this.addBuyValue = ''
          }
        }  //订单编号
        // if (num == 7) this.addBuyValue = this.queryData.remark ?this.queryData.remark :'' //买家备注
         if (num == 8){
          this.maxNumber=20
          if(this.queryData.expressNumber){
            this.addBuyValue = this.queryData.expressNumber
          }else{
             this.addBuyValue = ''
          }
        }//快递单号
        this.addBuyValue = this.addBuyValue.replace(/[,]/g, "\n").replace(/，/g,"\n");
        this.dialogShow = true;
    },
    comfirBtn() {
      let china=this.addBuyValue.replace(/，/g,',')
      let alertMsg = china.replace(/\n/g, ",").split(",");
      if (alertMsg.length > this.maxNumber) {
       
        this.keyNumberShow = true;
        this.printShow = true;
      } else {
        // this.keyNumberShow = false;
        this.printShow = false;
        if (this.inputNum == 1)this.queryData.wangwang = this.addBuyValue.replace(/\n/g, ",").replace(/，/g,',');
        if (this.inputNum == 2)this.queryData.to_mobile = this.addBuyValue.replace(/\n/g, ",").replace(/，/g,',');
        if (this.inputNum == 3)this.queryData.shopNumbe = this.addBuyValue.replace(/\n/g, ",").replace(/，/g,',');
        if (this.inputNum == 4)this.queryData.orderMoney = this.addBuyValue.replace(/\n/g, ",").replace(/，/g,',');
        if (this.inputNum == 5)this.queryData.orderNumber = this.addBuyValue.replace(/\n/g, ",").replace(/，/g,',');
        if (this.inputNum == 6)this.queryData.orderCode = this.addBuyValue.replace(/\n/g, ",").replace(/，/g,',');
        if (this.inputNum == 7)this.queryData.remark = this.addBuyValue.replace(/\n/g, ",").replace(/，/g,',');
        if (this.inputNum == 8)this.queryData.expressNumber = this.addBuyValue.replace(/\n/g, ",").replace(/，/g,',');
        this.dialogShow = false;
      }
    },
    noneBtn() {
    
      this.dialogShow = false;
      (this.keyNumberShow = false), (this.printShow = false);
    },

    handleKeyNumber(val) {
      let keyCode=val.replace(/，/g,',').split(',')
        if (keyCode.length > this.maxNumber) {
        this.printShow = true;
      } 
    },
    handleFontShow(){
      let china=this.addBuyValue.replace(/，/g,',')
      let aa=china.replace(/\n/g, ",")
      let keyCode=aa.split(',')
       if (keyCode.length-1 >= this.maxNumber) {
        this.keyNumberShow = true;
      }else{
         this.keyNumberShow = false;
      }
    },
    getBeginTime() {
      let now = new Date().getTime();
      let days = 34;
      let agoTimes = now - 86400 * 1000 * days;
      let time = new Date(agoTimes);
      let year = time.getFullYear();
      let mounth = time.getMonth() + 1;
      let date = time.getDate();
      mounth = mounth < 10 ? "0" + mounth : mounth;
      date = date < 10 ? "0" + date : date;
      return year + "-" + mounth + "-" + date + " 00:00:00";
    },
    getEndTime() {
      var time = new Date();
      var year = time.getFullYear();
      var mounth = time.getMonth() + 1;
      var date = time.getDate();
      mounth = mounth < 10 ? "0" + mounth : mounth;
      date = date < 10 ? "0" + date : date;
      return year + "-" + mounth + "-" + date + " 23:59:59";
    },
  },
  created() {
    this.searchInfo.startTime = [this.getBeginTime(), this.getEndTime()];
    this.searchInfo.Time = '1';
    this.searchInfo.status = '';
    this.searchInfo.print_state = '';
    this.searchInfo.ww_baby_count_money = '';
    this.searchInfo.delivery_limit_type= '';
    this.searchInfo.refund_state= '';
    this.searchInfo.remark_type = '';
  },
};
</script>

<style lang="less" scoped>
.box{
  display: flex;
  justify-content: space-between;
  align-items: center;

  .progress_title{
    font-size: 12px;
    line-height: 12px;
    color: #4E7ECF;
  }
  .progress_box{
    height: 15px;
    border-radius: 15px;
    background-color: #EBEEF5;
    padding: 3px;
    width: 264px;
    box-sizing: border-box;
    margin: 0px 3px;
  }
  .progress_number{
    font-size: 12px;
    line-height: 14px;
    color: #C4ABAD;
    margin-right: 8px;
  }
}
.search-form-container {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  min-width: 1200px;
}

/deep/ .el-input--mini .el-input__inner:focus {
  border-color: #ff5400 !important;
}
.search-form {
  overflow-y: hidden;
}
/deep/ .iconfont {
  font-size: 14px;
}
/deep/ #searchId::placeholder {
  color: red;
}
/deep/
  .searchClass
  .el-input__suffix
  .el-input__suffix-inner
  .el-select__caret {
  color: red;
}
/deep/ #searchId {
  color: red;
}
/deep/ .el-dialog {
  background-color: #fff;
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__body {
  padding: 0;
}
/deep/ .el-dialog__close {
  font-size: 20px;
  font-weight: 600;
  color: #2c2b3b;
  line-height: 2 !important;
}
/deep/ .el-dialog__close:hover {
  color: #2c2b3b;
}
/deep/ .contentClass .el-textarea__inner,
.contentClass .el-textarea__inner:focus {
  border-color: #4f4f4f;
}
.contentClass {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 10px 10px #999999;
}
.contentClass .topBg {
  width: 100%;
  height: 10px;
  background-color: #f6861e;
}
.contentClass .topTitle {
  padding: 0 10px;
  height: 30px;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  border-bottom: 2px solid #e6e6e6;
}
.contentClass .topTitle span:nth-child(1) {
  font-size: 18px;
  color: #365064;
  font-weight: 600;
  margin-right: 10px;
}
.contentClass .topTitle span:nth-child(2) {
  color: #808080;
}
.contentDiv {
  padding: 0 20px;
  margin-bottom: 5px;
}
.contentDiv p {
  line-height: 35px;
  color: #808080;
}
.contentFooter {
  padding: 10px;
  background-color: #f3f3f3;
  border-top: 1px solid #eaeaea;
  display: flex;
  justify-content: right;
}
.contentFooter div {
  width: 55px;
  height: 24px;
  border: 1px solid #b4b4b4;
  color: #000;
  line-height: 24px;
  text-align: center;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  background-image: -webkit-linear-gradient(bottom, #e5e5e5, #f6f6f6);
}
.contentFooter div:nth-child(1) {
  border: none;
  color: #fff;
  background: linear-gradient(#ffc04b, #ff6302);
  margin-right: 20px;
}
.contentClass2 .contentFooter div {
  border: none;
  background: none;
  background-color: #ff7614;
  color: #fff;
  display: inline-block;
  padding: 9px 13px;
  border-radius: 4px;
  width: 60px;
  height: 18px;
  line-height: 18px;
}
.contentClass2 .topTitle {
  font-size: 16px;
}
.contentClass2 .contentText {
  color: #000;
  font-size: 14px;
  padding: 20px 30px 50px 10px;
}
/deep/ .el-checkbox__label {
  padding-left: 5px;
}
.el-icon-question {
  color: #e83437;
  margin-left: 5px;
  cursor: pointer;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f5b136;
  border-color: #f5b136;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #492900;
}
/deep/ .el-checkbox.is-bordered.is-checked {
  border-color: #f5b136;
}
/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #f5b136;
}
/deep/ .el-checkbox__inner {
  border-color: #666;
}
.clickTag {
  display: inline-block;
  position: relative;
  z-index: 200;
  div {
    width: 150px;
    background-color: #fafea7;
    border: 1px solid #fcca54;
    position: absolute;
    top: 10px;
    left: 25px;
    font-size: 14px;
    line-height: 14px;
    padding: 0 10px 10px 5px;
    p:nth-child(1) {
      font-weight: 600;
      margin: 10px 0;
    }
  }
}
.print-show .content {
  font-size: 20px;
  text-align: center;
  height: 140px;
  line-height: 140px;
}
.print-show /deep/ .el-icon-warning {
  color: #f6891e;
  font-size: 35px;
  transform: translate(-5px, 6px);
}
.print-show /deep/ .el-dialog__header {
  padding: 0px;
}
</style>