<template>
  <div class="popup_box" :style="popup_style">
    <slot>
      <!-- 弹窗内容插槽 -->
    </slot>
  </div>
</template>
<script>
export default {
  name: "self_mask_popup",
  props: ['popup_style'],
}
</script>
<style lang="less" scoped>
.popup_box {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1001;
}
</style>