<template>
  <!-- 发货单设置发件人弹窗 -->
  <div> 
    <my-dialog-3
    :centerDialogVisible="dialogFahuoConsignerVisible"
    :popupWidth="'500px'"
    @updateVisible="updateFahuoConsignerVisible"
    @openDialog="openFahuoConsignerDialog"
    >
  <template slot="content">
    <div class="consigner-dialog">
      <span style="margin-bottom: 30px">请选择发货单发件人</span>
      <el-radio-group v-model="activeFahuoConsignerIndex">
        <el-radio
          v-for="(item, index) in fahuoConsignerList"
          :key="index"
          :label="index + ''"
          >{{ item.title }}</el-radio
        >
      </el-radio-group>
      <span v-show="fahuoConsignerList.length == 0"
        >暂无发件人信息，点击<el-button type="text" @click="jumptoSetting()"
          >这里</el-button
        >进行设置</span
      >
    </div>
  </template>
  <template slot="footer">
    <el-button
      class="footer-btn btn1"
      type="primary"
      @click="handleChooseFahuoConsigner"
      size="mini"
      >确定</el-button
    >
    <el-button
      class="footer-btn btn2"
      size="mini"
      @click="handleCancel"
      >取消</el-button
    >
  </template>
    </my-dialog-3>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import myDialog3 from "@/UI/myDialog3";
import { lodopSinglePrint,lodopBatchPrint } from "@/utils/functions.js";
import reprintDialog1 from "@/components/dialog/reprintDialog1";
export default {
    props:['orderInfoList','type'],
    components:{
        myDialog3,
        reprintDialog1
    },
    data() {
        return {
            // 发货单发货人设置弹窗变量
            activeFahuoConsignerIndex: "0",
            fahuoConsigner: {},
        }
    },
   computed: {
    ...mapState({
      fahuoConsignerList: (state) => state.print.fahuoConsignerList,
       // 打印参数
      printOrderInfo: (state) => state.batchprint.printOrderInfo,
    }),
    dialogFahuoConsignerVisible:{
        get(){
            return this.$store.state.batchprint.dialogFahuoConsignerVisible
        },
    }
    },
    methods: {
      handleCancel(){
          this.$store.commit('batchprint/SAVE',{dialogFahuoConsignerVisible:false})
      },
      // 跳转设置
      jumptoSetting() {
        this.$router.push("/setting");
      },
      // 发货单发件人设置弹窗函数
      updateFahuoConsignerVisible(val) {
        this.$store.commit('batchprint/SAVE',{dialogFahuoConsignerVisible:val})
      },
      openFahuoConsignerDialog() {
        this.$store.dispatch("print/get_fahuo_consigner_list");
      },
      handleChooseFahuoConsigner() {
        this.$store.commit("batchprint/SAVE", {dialogFahuoConsignerVisible:false});
        let fahuoConsigner = this.fahuoConsignerList[
          this.activeFahuoConsignerIndex
        ];
        this.fahuoConsigner = fahuoConsigner;
        this.doPrintFahuo()
      },
    // 打印函数
    doPrintFahuo() {
      let params;
      let orderInfoList;
      // 为1批量打印参数
      if(this.type==1){
        if (this.printOrderInfo.length == 0) {
        this.$message.error("请选择要打印的订单");
      } else {
        orderInfoList = this.orderInfoList.filter(item=>{
          return item.is_hang != 1
        })
        this.dialogFahuoVisible = false
          let mergeNo=[]//合单
          let orderNo=''//不合单
             for (let item of this.orderInfoList) {
              let tid=[]
              if(item.merge_order && item.merge_no==''){
                let tParamId=""
                for(let k in item.merge_order){
                  tid+=item.merge_order[k].transaction_id+ "|" + item.merge_order[k].create_time+ ","
                }
                tid=tid.substring(0,tid.lastIndexOf(","));
                tParamId+=tid+','
                tParamId=tParamId.substring(0,tParamId.lastIndexOf(","));
                mergeNo.push(tParamId)
              }else if(item.merge_order && item.merge_no){
                mergeNo.push(item.merge_no)
              }else{
                orderNo+=item.transaction_id + "|" + item.create_time + ","
              }
          }
          orderNo=orderNo.substring(0,orderNo.lastIndexOf(","));
          params={merge_no:mergeNo,order_no:orderNo}
      }
      }
      // 为2单个打印参数
      if(this.type==2){
          this.dialogFahuoPrintVisible = false
          let mergeNo=[]//合单
          let orderNo=''//不合单
          if(this.orderInfoList.merge_order){
            let tid=[]
            let tParamId=""
            for(let k in this.orderInfoList.merge_order){
              tid+=this.orderInfoList.merge_order[k].transaction_id+ "|" + this.orderInfoList.merge_order[k].create_time+ ","
            }
            tid=tid.substring(0,tid.lastIndexOf(","));
            tParamId+=tid+','
            tParamId=tParamId.substring(0,tParamId.lastIndexOf(","));
            mergeNo.push(tParamId)
          }else{
            orderNo+=this.orderInfoList.transaction_id + "|" + this.orderInfoList.create_time + ","
          }
          orderNo=orderNo.substring(0,orderNo.lastIndexOf(","));
          params={merge_no:mergeNo,order_no:orderNo}
          
      }
      let orderData=this.type==1?orderInfoList:this.orderInfoList
      this.$store
            .dispatch("batchprint/update_fahuo_state",{transaction_id:params})
            .then((res) => {
              if(res.code==20001){
                this.$message.error(res.message)
                return false
              }else{
                // 打印参数
                let printPrams={
                  LODOP: this.LODOP,
                  order_info_list:orderData,
                  consigner_info:this.fahuoConsigner
                }
                this.type==1? lodopBatchPrint(printPrams):lodopSinglePrint(
                 printPrams)
              this.$store.dispatch("batchprint/get_search_result")
              }
            });
    },
  },
}
</script>

<style>

</style>